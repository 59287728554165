// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiList-root MuiList-padding MuiList-dense css-1ontqvh{
    width:200px;
}

.css-1dmzujt{
    width:220px !important;
}


.inl{
    display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/Document/Table Modals/modal.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,sBAAsB;AAC1B;;;AAGA;IACI,qBAAqB;AACzB","sourcesContent":[".MuiList-root MuiList-padding MuiList-dense css-1ontqvh{\r\n    width:200px;\r\n}\r\n\r\n.css-1dmzujt{\r\n    width:220px !important;\r\n}\r\n\r\n\r\n.inl{\r\n    display: inline-block;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
