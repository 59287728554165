// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reglog{
    text-align:center;
    vertical-align:center;
    margin:2%;

}
img.login{
    height:40vh
}
img.orcid{
    height: 4vh;
    width: 4vh;
}`, "",{"version":3,"sources":["webpack://./src/components/login/login.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,SAAS;;AAEb;AACA;IACI;AACJ;AACA;IACI,WAAW;IACX,UAAU;AACd","sourcesContent":[".reglog{\r\n    text-align:center;\r\n    vertical-align:center;\r\n    margin:2%;\r\n\r\n}\r\nimg.login{\r\n    height:40vh\r\n}\r\nimg.orcid{\r\n    height: 4vh;\r\n    width: 4vh;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
