// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inline{
    display: inline-block;
    margin-left:2vw;
}
.inline_1vw{
    display: inline-block;

    margin-left: 1vw;
}
.icon-menu{
    width:30px
}
.exit{
    display: inline-block;
    color:black !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Document/ToolBar/tool.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,eAAe;AACnB;AACA;IACI,qBAAqB;;IAErB,gBAAgB;AACpB;AACA;IACI;AACJ;AACA;IACI,qBAAqB;IACrB,sBAAsB;AAC1B","sourcesContent":[".inline{\r\n    display: inline-block;\r\n    margin-left:2vw;\r\n}\r\n.inline_1vw{\r\n    display: inline-block;\r\n\r\n    margin-left: 1vw;\r\n}\r\n.icon-menu{\r\n    width:30px\r\n}\r\n.exit{\r\n    display: inline-block;\r\n    color:black !important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
