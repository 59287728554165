// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-stats{
    margin:0 2% 0 2%;
}

#card{
    margin: 0 20px 0 20px;
    float:left;
 }
#radial-container.normal{
    width: 100%;
    float:left;
}
#radial-container.active{
    width: 30%;
    float:left;
}
.subcontainer{
    padding: 2%;
    display:flex;
    width:100%;
    justify-content:center;

}
.navbar{
    display:flex;
    justify-content: flex-end;
}
.centerpie{
    display: flex;
    justify-content: center;
}
.btnclass:hover{
    color:royalblue;
    text-decoration: underline;
    cursor: pointer;
}
.btnclass{
    color:royalblue;
    text-decoration: underline;
}
.fixedsquare{
    overflow-x: hidden;
    overflow-y: auto;
    height:50vh
}
.centerbars{
    /*display: flex;*/
    justify-content: center;
}
.fixedsquare::-webkit-scrollbar {
    width: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Statistics/stats.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,UAAU;CACb;AACD;IACI,WAAW;IACX,UAAU;AACd;AACA;IACI,UAAU;IACV,UAAU;AACd;AACA;IACI,WAAW;IACX,YAAY;IACZ,UAAU;IACV,sBAAsB;;AAE1B;AACA;IACI,YAAY;IACZ,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,eAAe;IACf,0BAA0B;IAC1B,eAAe;AACnB;AACA;IACI,eAAe;IACf,0BAA0B;AAC9B;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB;AACJ;AACA;IACI,iBAAiB;IACjB,uBAAuB;AAC3B;AACA;IACI,UAAU;AACd","sourcesContent":[".container-stats{\r\n    margin:0 2% 0 2%;\r\n}\r\n\r\n#card{\r\n    margin: 0 20px 0 20px;\r\n    float:left;\r\n }\r\n#radial-container.normal{\r\n    width: 100%;\r\n    float:left;\r\n}\r\n#radial-container.active{\r\n    width: 30%;\r\n    float:left;\r\n}\r\n.subcontainer{\r\n    padding: 2%;\r\n    display:flex;\r\n    width:100%;\r\n    justify-content:center;\r\n\r\n}\r\n.navbar{\r\n    display:flex;\r\n    justify-content: flex-end;\r\n}\r\n.centerpie{\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n.btnclass:hover{\r\n    color:royalblue;\r\n    text-decoration: underline;\r\n    cursor: pointer;\r\n}\r\n.btnclass{\r\n    color:royalblue;\r\n    text-decoration: underline;\r\n}\r\n.fixedsquare{\r\n    overflow-x: hidden;\r\n    overflow-y: auto;\r\n    height:50vh\r\n}\r\n.centerbars{\r\n    /*display: flex;*/\r\n    justify-content: center;\r\n}\r\n.fixedsquare::-webkit-scrollbar {\r\n    width: 8px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
