
import axios from "axios";
import {ButtonGroup, Collapse} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import React, {useState, useEffect, useContext, createContext, useRef} from "react";
import Badge from 'react-bootstrap/Badge'
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import 'bootstrap/dist/css/bootstrap.min.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
import UploadIcon from '@mui/icons-material/Upload';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import ArticleIcon from '@mui/icons-material/Article';
import {AppContext} from "../../../App";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import {CircularProgress} from "@mui/material";
import Button from "@mui/material/Button";
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export default function LabelsClass(props){
    const { view,document_id,labels,modality,snackmessage,opensnack, curannotator,username,inarel,labelstosave,annotatedlabels } = useContext(AppContext);
    const [ShowList,SetShowList] = useState(false)
    const [DocumentID,SetDocumentID] = document_id
    const [Labels,SetLabels] = labels
    const [NotAdded,SetNotAdded] = useState([])
    const [ShowSelect,SetShowSelect] = useState(false)
    // const [AnnotatedLabels, SetAnnotatedLabels] = useState(false)
    const [AnnotatedLabels, SetAnnotatedLabels] = annotatedlabels
    const [LabelsToSave, SetLabelsToSave] = labelstosave
    const [InARel,SetInARel] = inarel
    const [CurAnnotator,SetCurAnnotator] = curannotator
    const [Username,SetUsername] = username
    const [Loading,SetLoading] = useState(false)
    const [SnackMessage,SetSnackMessage] = snackmessage;
    const [OpenSnack,SetOpenSnack] = opensnack
    const [Modality,SetModality] = modality
    const [View,SetView] = view

    function AdddeleteLabel(label){
        if(Modality === 2){
            SetOpenSnack(true)
            SetSnackMessage({'message':'You cannot annotate this document'})
        }
        else{
            SetLoading(true)
            if(CurAnnotator === Username){
                if(AnnotatedLabels.indexOf(label) === -1) {
                    // AnnotatedLabels.push(label)
                    var labels = NotAdded.filter(o => o !== label)
                    SetNotAdded(labels)
                    SetAnnotatedLabels([...AnnotatedLabels,label])
                    axios.post('labels/insert',{label:label})
                        .then(response=>{
                            var labels = NotAdded.filter(o => o !== label)
                            SetNotAdded(labels)
                            SetAnnotatedLabels([...AnnotatedLabels,label])
                            SetLoading(false)

                        })

                }
                else{
                    axios.delete('labels',{data:{label:label}})
                        .then(response=>{
                            SetNotAdded([...NotAdded,label])
                            var labels = AnnotatedLabels.filter(o => o !== label)
                            SetAnnotatedLabels(labels)
                            SetLoading(false)

                        })

                }
            }else{
                if(AnnotatedLabels.indexOf(label) !== -1) {
                    axios.post('labels/copy',{label:label}).then(res=>console.log(res)).catch(error=>console.log(error))
                    SetLoading(false)

                }

            }
        }



    }

    useEffect(()=>{
        if(AnnotatedLabels){
            var notadded = Labels.filter(o => (AnnotatedLabels).indexOf(o) === -1)
            SetNotAdded(notadded)

        }


    },[AnnotatedLabels])


    const labelstheme = createTheme({
        palette: {
            added: {
                main: 'rgb(103, 148, 54)',
                contrastText: '#fff',
            },
            not_added: {
                main: 'rgb(66, 122, 161)',
                contrastText: '#fff',
            },

        },
    });

    return(
        <div>
            <Button disabled={View === 4} onClick={SetShowList(prev=>!prev)} variant="text">Document Labels <i>({AnnotatedLabels.length})</i></Button>


        <div>
            <Collapse in={ShowList}>
                {AnnotatedLabels ? <ThemeProvider theme={labelstheme}>
                    {Labels.map(o=>
                        <span><Chip color={'not_added'} disabled={Loading} variant={NotAdded.indexOf(o) !== -1 ? "outlined": "filled"} label={o} size="small" onClick={()=>AdddeleteLabel(o)}/>{' '}</span>

                    )}

                </ThemeProvider>:<div className='loading'>
                    <CircularProgress />
                </div>}
            </Collapse>



        </div>
        </div>
    );
}