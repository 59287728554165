// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addcollectionclass{
    margin-top: 2%;
    margin-bottom: 2%;
}
.addcontainer{
    padding:3%
}
.uploadDoc{
    border-radius: 4px;
    height:100px
}
.uploadfiles{
    /*display: flex;*/
    /*justify-content: center;*/
    text-align: center;
    margin:2%
}
.clickable:hover{
    cursor:pointer;
    color:royalblue;
}
.collectionButt{
    margin-right: 2%;
}
.confirmButt{
    margin: 2%;
    display: inline-block;
}
.loading{
    display: flex;
    justify-content: center;
    margin-top: 5vh;
}
.labels_diag{
    margin:3%
}
.slider-picker{
    width:30vw !important;
}
.center_elements{
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
    margin-top: 2%;
}
.new_size{
    font-size:1.5em;
}
.buttontem:hover{
    cursor:pointer;
    color:royalblue;
    text-decoration: underline;

}
.buttontem{
    color:royalblue;

}`, "",{"version":3,"sources":["webpack://./src/components/Collections/collection.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;AACrB;AACA;IACI;AACJ;AACA;IACI,kBAAkB;IAClB;AACJ;AACA;IACI,iBAAiB;IACjB,2BAA2B;IAC3B,kBAAkB;IAClB;AACJ;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,qBAAqB;AACzB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI;AACJ;AACA;IACI,qBAAqB;AACzB;AACA;IACI,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,eAAe;AACnB;AACA;IACI,cAAc;IACd,eAAe;IACf,0BAA0B;;AAE9B;AACA;IACI,eAAe;;AAEnB","sourcesContent":[".addcollectionclass{\r\n    margin-top: 2%;\r\n    margin-bottom: 2%;\r\n}\r\n.addcontainer{\r\n    padding:3%\r\n}\r\n.uploadDoc{\r\n    border-radius: 4px;\r\n    height:100px\r\n}\r\n.uploadfiles{\r\n    /*display: flex;*/\r\n    /*justify-content: center;*/\r\n    text-align: center;\r\n    margin:2%\r\n}\r\n.clickable:hover{\r\n    cursor:pointer;\r\n    color:royalblue;\r\n}\r\n.collectionButt{\r\n    margin-right: 2%;\r\n}\r\n.confirmButt{\r\n    margin: 2%;\r\n    display: inline-block;\r\n}\r\n.loading{\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-top: 5vh;\r\n}\r\n.labels_diag{\r\n    margin:3%\r\n}\r\n.slider-picker{\r\n    width:30vw !important;\r\n}\r\n.center_elements{\r\n    display:flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-bottom: 2%;\r\n    margin-top: 2%;\r\n}\r\n.new_size{\r\n    font-size:1.5em;\r\n}\r\n.buttontem:hover{\r\n    cursor:pointer;\r\n    color:royalblue;\r\n    text-decoration: underline;\r\n\r\n}\r\n.buttontem{\r\n    color:royalblue;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
