// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tool_bar{
    padding-bottom: 1%;
}
.del{
    float:right;
    margin-right:2vw;
}
.header_bar{
    /*height:5vh;*/
    margin:0.5vh 5%;
    /*margin-left:5%;*/
    /*margin-right:5%;*/
    position: relative;
    /*z-index:12;*/
    /*width:100%;*/
    /*background-color: rgb(98,130,149);*/
}

/*.MuiToolbar-root{*/
/*    min-height: 5vh !important;*/
/*    height: 5vh;*/
/*}*/
/*.css-r8u8y9{*/
/*  width:220px!important;*/

/*}*/`, "",{"version":3,"sources":["webpack://./src/components/BaseComponents/toolbar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,cAAc;IACd,qCAAqC;AACzC;;AAEA,oBAAoB;AACpB,kCAAkC;AAClC,mBAAmB;AACnB,IAAI;AACJ,eAAe;AACf,2BAA2B;;AAE3B,IAAI","sourcesContent":[".tool_bar{\r\n    padding-bottom: 1%;\r\n}\r\n.del{\r\n    float:right;\r\n    margin-right:2vw;\r\n}\r\n.header_bar{\r\n    /*height:5vh;*/\r\n    margin:0.5vh 5%;\r\n    /*margin-left:5%;*/\r\n    /*margin-right:5%;*/\r\n    position: relative;\r\n    /*z-index:12;*/\r\n    /*width:100%;*/\r\n    /*background-color: rgb(98,130,149);*/\r\n}\r\n\r\n/*.MuiToolbar-root{*/\r\n/*    min-height: 5vh !important;*/\r\n/*    height: 5vh;*/\r\n/*}*/\r\n/*.css-r8u8y9{*/\r\n/*  width:220px!important;*/\r\n\r\n/*}*/"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
