// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu:hover{
    color:royalblue;
    text-decoration: underline;
}

.dem{
    line-height: 1.5;
    margin:20px 0;
}
.videoclass{
    text-align: center;
    margin:50px;
}`, "",{"version":3,"sources":["webpack://./src/components/Instructions/instructions.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".menu:hover{\r\n    color:royalblue;\r\n    text-decoration: underline;\r\n}\r\n\r\n.dem{\r\n    line-height: 1.5;\r\n    margin:20px 0;\r\n}\r\n.videoclass{\r\n    text-align: center;\r\n    margin:50px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
